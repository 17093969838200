import { Trans } from '@lingui/macro';
import Button from 'components/atoms/Button/Button';
import { CardIconProps } from 'components/atoms/CardIcon/CardIcon.model';
import { IconFA } from 'components/atoms/IconFA/IconFA';
import Image from 'components/atoms/Image/Image';
import { CardInfoImageSection } from 'components/CardInfoImageSection/CardInfoImageSection';
import { CompanyWorkWithUsSection } from 'components/CompanyWorkWithUsSection/CompanyWorkWithUsSection';
import { Comparison } from 'components/Comparison/Comparison';
import { ContactFormSection } from 'components/ContactFormSection/ContactFormSection';
import { ContactFormSectionProps } from 'components/ContactFormSection/ContactFormSection.model';
import { ElevateDevelopmentGameSection } from 'components/ElevateDevelopmentGameSection/ElevateDevelopmentGameSection';
import { ElevateDevelopmentGameSectionProps } from 'components/ElevateDevelopmentGameSection/ElevateDevelopmentGameSection.model';
import { GreatPrideSection } from 'components/GreatPrideSection/GreatPrideSection';
import { GreatPrideSectionProps } from 'components/GreatPrideSection/GreatPrideSection.model';
import { HeaderContentModel } from 'components/Header/Header.model';
import Layout from 'components/Layout';
import { OurHistoryVision } from 'components/OurHistoryVision/OurHistoryVision';
import { OurHistoryVisionProps } from 'components/OurHistoryVision/OurHistoryVision.model';
import Seo from 'components/SEO';
import { SustainableGoalsSection } from 'components/SustainableGoalsSection/SustainableGoalsSection';
import { SustainableGoalsSectionProps } from 'components/SustainableGoalsSection/SustainableGoalsSection.model';
import { ThankYouSection } from 'components/ThankYouSection/ThankYouSection';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { featureLists, PricingCardData } from 'src/page-components/pricing/pricingCardsData';

import PricingSection from 'src/page-components/pricing/PricingSection';
import { PricingSectionProps } from 'src/page-components/pricing/PricingSection.model';

const FractalCloudVsHumanitecIT = () => {
  const [thankYouSectionSubmitted, setThankYouSectionSubmitted] = useState(false);

  const elevateDevelopmentGame: ElevateDevelopmentGameSectionProps = {
    id: 'product',
    title: 'Vuoi rivoluzionare il tuo modo di sviluppare?',
    subtitle:
      'Rivoluziona il tuo processo di sviluppo software con la nostra piattaforma Saas sicura, flessibile e collaborativa.',
    cardIcons: [
      {
        iconName: 'elevate-development-game-section/cost-reduction',
        title: 'Riduzione dei Costi del 30%',
        subTitle:
          'Ottimizza i flussi di lavoro di sviluppo, eliminando colli di bottiglia e portando a significativi risparmi sui costi infrastrutturali per le aziende.',
      },
      {
        iconName: 'elevate-development-game-section/infrastructure-code',
        title: 'Riduzione del Codice Infrastrutturale di 10 volte',
        subTitle:
          'Semplifica la gestione e la scrittura IaC (Infrastructure as Code), liberando tempo e risorse preziose per i tuoi sviluppatori',
      },
      {
        iconName: 'elevate-development-game-section/multi-cloud',
        title: 'Supporto Multi-Cloud',
        subTitle:
          'Consente alle aziende di sfruttare la potenza di più fornitori cloud, tra cui GCP, Azure, AWS e configurazioni Kubernetes private.',
      },
      {
        iconName: 'elevate-development-game-section/cloud-service',
        title: 'Servizi Cloud Completi',
        subTitle: `Supporta FaaS, PaaS, CaaS e IaaS a livello agnostico, facilitando l'adozione di soluzioni cloud-native da parte delle aziende.`,
      },
    ],
    ctaLabel: 'Scopri Fractal Cloud',
  };

  const headersComparison = ['Humanitech', 'Fractal'];

  const rowsComparison = [
    { title: 'Supporto per più cloud', values: [true, true] },
    { title: 'Enterprise ready', values: [true, true] },
    { title: 'No YALM o Terraform', values: [false, true] },
    { title: 'Non è necessario personale specializzato', values: [false, true] },
    { title: 'Template multi-cloud', values: [false, true] },
    { title: 'Componenti sicuri', values: [false, true] },
    { title: 'Crea ed esponi operazioni infrastrutturali personalizzate', values: [false, true] },
    { title: 'Aggiornamento automatico dei componenti', values: [false, true] },
    { title: 'Multi-cloud Native', values: [false, true] },
  ];

  const imageMapping = {
    Humanitech: 'companies/humanitec-logo',
    Fractal: 'companies/fractal-logo',
  };

  const headerContent: HeaderContentModel[] = [
    {
      name: 'Prodotti',
      url: '#product',
    },
    {
      name: 'Compara',
      url: '#compare',
    },
    {
      name: 'Chi siamo',
      url: '#about-us',
    },
    {
      name: 'Prezzi',
      url: '#pricing',
    },
    {
      name: 'Contattaci',
      url: '#contact-us',
      type: 'contact',
    },
  ];

  const cardIconsLiveSystem: CardIconProps[] = [
    {
      title: 'Distribuzioni più veloci',
      subTitle: `Implementa l'infrastruttura con il minimo codice, accelerando il time-to-market e le iterazioni.`,
      iconName: 'live-system-first-section/faster-deployments',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Sicurezza integrata',
      subTitle: `Componenti preconfigurati garantiscono ambienti sicuri fin dall'inizio.`,
      iconName: 'live-system-first-section/built-in-security',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Blueprint riutilizzabili',
      subTitle:
        'Estrai e condividi Blueprint tra i team, rendendo la scalabilità e la collaborazione semplici e immediate.',
      iconName: 'live-system-first-section/reusable-blueprints',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const cardIconsFractal: CardIconProps[] = [
    {
      title: 'Semplifica la complessità del cloud',
      subTitle:
        'Gestisci la complessità del cloud-native con facilità grazie ai Blueprint riutilizzabili.',
      iconName: 'fractal-first-section/simplifies-cloud-complexity',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Conformità integrata',
      subTitle:
        'I Blueprint versionati garantiscono automaticamente la conformità mentre i sistemi evolvono.',
      iconName: 'fractal-first-section/compliance-by-design',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Multi-cloud da subito',
      subTitle:
        'Distribuisci istantaneamente i Blueprint su qualsiasi provider cloud con configurazioni minime.',
      iconName: 'fractal-first-section/effortless-multi-cloud',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const featureLists: PricingCardData = [
    [
      {
        cardInfo: {
          titleCard: 'Fractal FF',
          subtitleCard: 'Forever Free',
          pricing: 'Free',
          ctaLabel: 'Inizia ora',
        },

        content: [
          { '5 Fractal inclusi': true },
          { '2 Livesystem inclusi': true },
          { '1 Agent incluso': true },
          { '1 User incluso': true },
          { 'Livesystem privati': true },
        ],
      },
      {
        header: 'Fractal Cloud',
        content: [
          { 'Supporto SDK': true },
          { 'Livesystem pubblici': false },
          { 'Fractals privati': false },
          { 'Enterprise-ready': false },
        ],
      },
      {
        header: 'Sicurezza',
        content: [
          { MFA: true },
          { 'Tracciamento della Sessioni': true },
          { 'Provider di Identità Personale Pubblica': true },
          { 'Provider di Identità Aziendale Pubblica': false },
          { 'RBAC (Controllo degli Accessi Basato sui Ruoli)': false },
          { 'Provider di Identità On-Premises': false },
        ],
      },
      {
        header: 'Collaborazione',
        content: [
          { Cronologia: false },
          { 'Revisioni delle Modifiche': false },
          { 'Tracciamento dei Problemi': false },
        ],
      },
      {
        header: 'Funzionalità Avanzate',
        content: [
          { 'Possibilità di Configurazione Dedicata On-Premises': false },
          { 'Scansione Cloud': false },
          { 'Analisi dei Costi': false },
          { 'Previsione e Consulenza sui Costi': false },
          { 'Riduzione dei Costi': false },
        ],
      },
    ],
    [
      {
        cardInfo: {
          titleCard: 'Fractal Workgroup',
          subtitleCard: 'Registrati come singolo utente',
          pricing: '€200/mese',
          customClassPricing: 'text-blue-900',
          subTitlePricing: 'Per utente',
          ctaLabel: 'Inizia ora',
        },
        content: [
          { '5 Fractal inclusi': true },
          { '2 Livesystem inclusi': true },
          { '2 Agent inclusi': true },
          { '1 User inclusi': true },
          { 'Livesystem privati': true },
        ],
      },
      {
        header: 'Fractal Cloud',
        content: [
          { 'Supporto SDK': true },
          { 'Livesystem pubblici': true },
          { 'Fractals privati': true },
          { 'Enterprise-ready': true },
        ],
      },
      {
        header: 'Sicurezza',
        content: [
          { MFA: true },
          { 'Tracciamento della Sessioni': true },
          { 'Provider di Identità Personale Pubblica': true },
          { 'Provider di Identità Aziendale Pubblica': true },
          { 'RBAC (Controllo degli Accessi Basato sui Ruoli)': true },
          { 'Provider di Identità On-Premises': false },
        ],
      },
      {
        header: 'Collaborazione',
        content: [
          { Cronologia: true },
          { 'Revisioni delle Modifiche': false },
          { 'Tracciamento dei Problemi': false },
        ],
      },
      {
        header: 'Funzionalità Avanzate',
        content: [
          { 'Possibilità di Configurazione Dedicata On-Premises': false },
          { 'Scansione Cloud': false },
          { 'Analisi dei Costi': false },
          { 'Previsione e Consulenza sui Costi': false },
          { 'Riduzione dei Costi': false },
        ],
      },
    ],
    [
      {
        cardInfo: {
          titleCard: 'Fractal Enterprise',
          subtitleCard: 'Potenzia il tuo busines',
          pricing: 'Richiedi un preventivo',
          ctaLabel: 'Inizia ora',
        },
        content: [
          { 'Fractal illimitati': true },
          { 'Livesystem illimitati': true },
          { 'Agent illimitati': true },
          { 'User illimitati': true },
          { 'Livesystem privati': true },
        ],
      },
      {
        header: 'Fractal Cloud',
        content: [
          { 'Supporto SDK': true },
          { 'Livesystem pubblici': true },
          { 'Fractals privati': true },
          { 'Enterprise-ready': true },
        ],
      },
      {
        header: 'Sicurezza',
        content: [
          { MFA: true },
          { 'Tracciamento della Sessioni': true },
          { 'Provider di Identità Personale Pubblica': true },
          { 'Provider di Identità Aziendale Pubblica': true },
          { 'RBAC (Controllo degli Accessi Basato sui Ruoli)': true },
          { 'Provider di Identità On-Premises': true },
        ],
      },
      {
        header: 'Collaborazione',
        content: [
          { Cronologia: true },
          { 'Revisioni delle Modifiche': true },
          { 'Tracciamento dei Problemi': true },
        ],
      },
      {
        header: 'Funzionalità Avanzate',
        content: [
          { 'Possibilità di Configurazione Dedicata On-Premises': true },
          { 'Scansione Cloud': true },
          { 'Analisi dei Costi': true },
          { 'Previsione e Consulenza sui Costi': true },
          { 'Riduzione dei Costi': true },
        ],
      },
    ],
  ];

  const pricing: PricingSectionProps = {
    title: 'I nostri prezzi',
    featureLists: featureLists,
    requestQuoteUrl: '#contact-us',
    targetBlank: false,

    expandCloseCards: {
      expandCardsLabel: 'visualizza il piano completo',
      closeCardsLabel: 'nascondi il piano completo',
    },
  };

  const isContactFormSubmitted = () => {
    setThankYouSectionSubmitted(true);
  };

  const contactForm: ContactFormSectionProps = {
    id: 'contact-us',
    isSubmitted: isContactFormSubmitted,
    title: 'Contattaci',
    subtitle: 'Inserisci i tuoi dati per richiedere informazioni o una demo',
    form: {
      name: {
        label: 'Nome',
        placeholder: 'Inserisci il tuo nome',
        requiredLabel: 'Il Nome è obbligatorio',
      },
      company: {
        label: 'Azienda',
        placeholder: `Inserisci il nome dell'azienda`,
        requiredLabel: 'Nome Azienda è obbligatorio',
      },
      phone: {
        label: 'Numero',
        placeholder: 'Inserisci il numero di telefono',
        requiredLabel: 'Il numero di telefono è obbligatorio',
      },
      email: {
        label: 'E-mail aziendale',
        placeholder: `Inserisci l'e-mail aziendale`,
        requiredLabel: `L'e-mail aziendale è obbligatoria`,
        invalidLabel: 'Formato email non valido',
      },
      message: {
        label: 'Messaggio',
        placeholder: 'Inserisci messaggio',
        requiredLabel: 'Il messaggio è obbligatorio',
      },
    },
    submittingLabels: {
      send: 'Invia messaggio',
      sending: 'Invio...',
      failed: 'Oh no! Qualcosa è andato storto! Per favore, ricarica la pagina e riprova',
      submitSuccessTitle: 'Grazie per averci contattato!',
      submitSuccessSubTitle: 'Riceverai una risposta il prima possibile!',
    },
  };

  const greatPride: GreatPrideSectionProps = {
    title: 'Con orgoglio ci dedichiamo a',
    cardIcons: [
      {
        iconName: 'great-pride-section/legacy-competitive',
        title: 'Trasformare i Sistemi Legacy in Vantaggi Competitivi',
        subTitle:
          'Rivoluzioniamo i sistemi ERP mainframe trasformandoli in fonti di eventi ultraveloci con dati in tempo reale in streaming. Potenziamo i microservizi in cloud, eliminiamo i processi notturni e liberiamo il potenziale dei sistemi legacy per favorire la crescita aziendale.',
      },
      {
        iconName: 'great-pride-section/revenue-streams',
        title: 'Creare Nuove Fonti di Ricavo',
        subTitle:
          "Sfruttiamo il potenziale dell'innovazione reinventando i prodotti on-premises e trasformandoli in soluzioni SaaS scalabili, generando nuove fonti di ricavo. Sblocchiamo risorse nascoste e portiamo le aziende a nuovi livelli di successo grazie alla nostra guida esperta.",
      },
      {
        iconName: 'great-pride-section/customer-succeed',
        title: 'Supportare il Successo dei Clienti',
        subTitle:
          "Promuoviamo la crescita attraverso soluzioni complete: trasformazioni Cloud Native, infrastrutture automatizzate, sicurezza, formazione e molto altro. Aiutiamo a dominare il panorama del cloud, raggiungere l'efficienza e portare il successo a livelli sempre più alti. Insieme possiamo raggiungere traguardi straordinari.",
      },
    ],
  };

  const ourHistoryVision: OurHistoryVisionProps = {
    id: 'about-us',
    title: 'La Nostra Storia e Visione',
    subtitle: 'Dalla Sicilia all’Innovazione Globale',
    text: 'YanchWare è un’azienda multinazionale con sedi in Italia e Danimarca, specializzata in Cloud Computing e Cybersecurity. Il nostro team internazionale, composto da sviluppatori, designer visivi e professionisti del marketing, collabora per offrire soluzioni all’avanguardia.',
    secondText:
      'Ci impegniamo a fornire alle imprese gli strumenti, i servizi e la formazione necessari per raggiungere i loro obiettivi e crescere autonomamente in un panorama aziendale in continua evoluzione. In YanchWare, il nostro percorso è unico quanto le innovazioni che portiamo al settore del cloud computing.',
  };

  const sustainableGoals: SustainableGoalsSectionProps = {
    title: 'Fractal Cloud: la migliore Internal Developer Platform',
    subtitle:
      'Fractacl Cloud rivoluziona il futuro della tecnologia, fornendo a sviluppatori, aziende e comunità le risorse per essere protagonisti del cambiamento.',
    cardIcons: [
      {
        iconName: 'sustainable-goals-section/quality-education',
        title: 'Il tuo team di sviluppo',
        subTitle:
          'Gestisce autonomamente l’infrastruttura necessaria per le applicazioni. Una governance semplice, senza complessità o risorse specializzate.',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
      {
        iconName: 'sustainable-goals-section/economical-growth',
        title: 'La tua azienda',
        subTitle:
          'Riprende il controllo decisionale e sceglie liberamente i fornitori. Un controllo granulare, senza costose piattaforme interne.',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
      {
        iconName: 'sustainable-goals-section/sustainable-comunities',
        title: 'I Centri di Eccellenza',
        subTitle:
          'Si concentrano sui problemi reali, senza limitarsi a tecnologie predefinite. Danno priorità al valore aziendale e alla rapidità di esecuzione',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
    ],
  };

  return (
    <Layout
      className="fractal-page"
      contactForm={false}
      header={{ isVisible: true, customHeader: headerContent }}
    >
      <Seo title="Fractal Cloud vs Humanitec" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="hero-wrapper">
        <div className="flex flex-col justify-center items-center pt-40 m-auto h-[35rem] md:h-auto">
          <div className="text-white text-center px-9 mb-6 md:mb-4">
            <h1 className="text-4xl mb-6 md:mb-4">
              <Trans>Fractal Cloud</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                La migliore Internal Developer Platform che supporta l'intero ciclo di vita dello
                sviluppo software
              </Trans>
            </p>
          </div>

          <div className="relative w-full hidden md:flex justify-center h-[29rem]">
            <Image name="fractal-vs-terraform/hero-image" className="max-w-[100rem]" />
            <div className="absolute inset-0 bg-gradient-to-t from-[#1e1374] to-transparent h-full" />
            <div className="absolute bottom-20">
              <a href="#pricing_cards">
                <Button kind="Primary" className="w-full text-nowrap">
                  <div className="flex items-center gap-3">
                    <span>Inizia ora</span>
                    <IconFA icon="arrow-right" className="w-4" />
                  </div>
                </Button>
              </a>
            </div>
          </div>

          <div className="block md:hidden">
            <a href="#pricing_cards">
              <Button kind="Primary" className="w-full text-nowrap">
                <div className="flex items-center gap-3">
                  <span>Get Started</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>

      <ElevateDevelopmentGameSection {...elevateDevelopmentGame} />

      <Comparison
        id="compare"
        title="Sfida tra Funzionalità"
        subtitle="Quale piattaforma offre le migliori caratteristiche per lo sviluppo del tuo software? A te la scelta"
        headers={headersComparison}
        showHeaderTitle={false}
        rows={rowsComparison}
        imageMapping={imageMapping}
      />

      <CompanyWorkWithUsSection title="Alcuni dei nostri clienti" />

      <div id="pricing">
        <PricingSection {...pricing} />
      </div>

      {thankYouSectionSubmitted ? (
        <ThankYouSection
          title="Grazie per averci contattato!"
          subtitle="Riceverai una risposta il prima possibile!"
        />
      ) : (
        <ContactFormSection {...contactForm} />
      )}

      <section className="mt-40 m-8">
        <div className="text-center mb-6">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            Progettato e realizzato per le esigenze della tua azienda
          </h2>

          <div className="flex justify-center">
            <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
              Due approcci disponibili per supportare la tua organizzazione in ogni situazione
            </p>
          </div>
        </div>
        <CardInfoImageSection
          title="Live-System First"
          subtitle="Distribuzione rapida focalizzata sui sistemi finali, ideale per PoC e progetti innovativi."
          cardIcons={cardIconsLiveSystem}
          className="flex flex-col-reverse lg:flex-row"
          imageName="live-system-first-section/live-system-image"
        />
        <CardInfoImageSection
          title="Fractal First"
          subtitle="Riutilizzabilità, automazione e governance cloud a livello enterprise per la tua organizzazione."
          cardIcons={cardIconsFractal}
          className="flex flex-col-reverse lg:flex-row-reverse"
          imageName="fractal-first-section/fractal-first-image"
          sectionClassName="mt-40"
        />
      </section>

      <section className="flex flex-col items-center justify-center max-w-[70rem] h-fit m-auto mt-64">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-8">
          <Trans>I NOSTRI CERTIFICATI</Trans>
        </h2>

        <div className="w-full flex flex-col md:flex-row gap-6 items-center justify-between">
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Microsoft" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Google_Cloud" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/AWS" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Oracle" />
          </div>
        </div>
      </section>

      <GreatPrideSection {...greatPride} />
      <OurHistoryVision {...ourHistoryVision} />
      <SustainableGoalsSection {...sustainableGoals} />

      <section className="flex flex-col justify-center items-center my-64 m-auto px-9">
        <div className="text-center">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-4">
            <Trans>Unisciti alla Rivoluzione del Cloud</Trans>
          </h2>
          <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[80rem] mb-6">
            Sei pronto a trasformare il tuo futuro?
          </p>
          <p className="text-[16px] font-[400] leading-[175%] max-w-[50rem] mb-8">
            Saremo al tuo fianco per guidarti verso il successo, unendo passione e tecnologia per
            trasformare le idee in realtà. Insieme sbloccheremo il pieno potenziale del cloud
            computing, alimenteremo l’innovazione e daremo nuova forza a persone e organizzazioni in
            tutto il mondo.
          </p>
          <div className="flex justify-center">
            <a href="#contact-us">
              <Button kind="Primary" className=" text-nowrap py-">
                <div className="flex items-center gap-3">
                  <span>Inizia ora</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default FractalCloudVsHumanitecIT;
